import React, { useState } from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { Link } from "gatsby"

//animation
import { Fade } from "react-reveal"

// svg decorations
// import "@components/svg-decorations/svg-decorations.scss"
import WaveTop from "@components/svg-decorations/waveTop"

import Sticky from "react-sticky-el"
import _ from "lodash"

import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

import oval1 from "@images/oval.svg"
import oval2 from "@images/oval-2.svg"
import oval3 from "@images/oval-3.svg"

const plans = [
	{
		name: "Pro Plan",
		description: "50-70 Prospects Per Day [1500+/m]",
		monthly: 5000,
		annual: 4000,
		url: "pro-plan",
		usage: [
			{
				title: "Multi-stage Voicemail Drops",
				limit: "No",
			},
			{
				title: "Customized Images in Outbound Campaigns",
				limit: "No",
			},
			{
				title: "Appointment Setting Sales Rep",
				limit: "No",
			},
			{
				title: "Personalized Email First Line",
				limit: "No",
			},
			{
				title: "A.I. Scheduling Assistant",
				limit: "No",
			},
			{
				title: "Direct Dial Numbers",
				limit: "No",
			},
			{
				title: "Technology Based Lead Searches",
				limit: "No",
			},
			{
				title: "Manual lead qualification/vetting",
				limit: "No",
			},
			{
				title: "Appointment tracking",
				limit: "No",
			},
			{
				title: "Pre-meeting reminders",
				limit: "No",
			},
			{
				title: "Direct calendar bookings & sync",
				limit: "No",
			},
			{
				title: "Up To 80h of Manual Lead Chasing per month",
				limit: "No",
			},
		],
		essentials: [
			{
				title: "50-70 Prospects Per Day [1500+/m]",
				limit: "Yes",
			},
			{
				title: "Lead list built and approved",
				limit: "Yes",
			},
			{
				title: "Outreach campaigns written and approved",
				limit: "Yes",
			},
			{
				title: "Email outbound prospecting & follow-up",
				limit: "Yes",
			},
			{
				title: "Gather current and past company urls",
				limit: "Yes",
			},
			{
				title: "Validated business emails",
				limit: "Yes",
			},
			{
				title: "Enterprise grade VPN login protection",
				limit: "Yes",
			},
			{
				title: "Monthly video reporting",
				limit: "Yes",
			},
			{
				title: "Dmark, Dkim, SPF record verification & installment",
				limit: "Yes",
			},
			{
				title: "Enrich & track entire prospect list",
				limit: "Yes",
			},
			{
				title: "Social Profile Optimization Guide",
				limit: "Yes",
			},
			{
				title: "Current client exclusions",
				limit: "Yes",
			},
		],
		advanced: [
			{
				title: "Split testing copy monthly",
				limit: "Yes",
			},
			{
				title: "Unlimited messaging update requests",
				limit: "Yes",
			},
			{
				title: "Spam monitoring/fixing",
				limit: "Yes",
			},
			{
				title: "Full domain prep and warmup",
				limit: "Yes",
			},
			{
				title: "A.I. lead response qualification",
				limit: "Yes",
			},
			{
				title: "Logging email responses",
				limit: "Yes",
			},
			{
				title: "Advanced metrics dashboard",
				limit: "Yes",
			},
		],
	},
	{
		name: "Enterprise Plan",
		description: "50-70 Prospects Per Day [1500+/m]",
		monthly: 7000,
		annual: 6000,
		url: "enterprise-plan",
		usage: [
			{
				title: "Multi-stage Voicemail Drops",
				limit: "Yes",
			},
			{
				title: "Customized Images in Outbound Campaigns",
				limit: "Yes",
			},
			{
				title: "Appointment Setting Sales Rep",
				limit: "Yes",
			},
			{
				title: "Personalized Email First Line",
				limit: "Yes",
			},
			{
				title: "A.I. Scheduling Assistant",
				limit: "Yes",
			},
			{
				title: "Direct Dial Numbers",
				limit: "Yes",
			},
			{
				title: "Technology Based Lead Searches",
				limit: "Yes",
			},
			{
				title: "Manual lead qualification/vetting",
				limit: "Yes",
			},
			{
				title: "Appointment tracking",
				limit: "Yes",
			},
			{
				title: "Pre-meeting reminders",
				limit: "Yes",
			},
			{
				title: "Direct calendar bookings & sync",
				limit: "Yes",
			},
			{
				title: "Up To 80h of Manual Lead Chasing per month",
				limit: "Yes",
			},
		],
		essentials: [
			{
				title: "50-70 Prospects Per Day [1500+/m]",
				limit: "Yes",
			},
			{
				title: "Lead list built and approved",
				limit: "Yes",
			},
			{
				title: "Outreach campaigns written and approved",
				limit: "Yes",
			},
			{
				title: "Email outbound prospecting & follow-up",
				limit: "Yes",
			},
			{
				title: "Gather current and past company urls",
				limit: "Yes",
			},
			{
				title: "Validated business emails",
				limit: "Yes",
			},
			{
				title: "Enterprise grade VPN login protection",
				limit: "Yes",
			},
			{
				title: "Monthly video reporting",
				limit: "Yes",
			},
			{
				title: "Dmark, Dkim, SPF record verification & installment",
				limit: "Yes",
			},
			{
				title: "Enrich & track entire prospect list",
				limit: "Yes",
			},
			{
				title: "Social Profile Optimization Guide",
				limit: "Yes",
			},
			{
				title: "Current client exclusions",
				limit: "Yes",
			},
		],
		advanced: [
			{
				title: "Split testing copy monthly",
				limit: "Yes",
			},
			{
				title: "Unlimited messaging update requests",
				limit: "Yes",
			},
			{
				title: "Spam monitoring/fixing",
				limit: "Yes",
			},
			{
				title: "Full domain prep and warmup",
				limit: "Yes",
			},
			{
				title: "A.I. lead response qualification",
				limit: "Yes",
			},
			{
				title: "Logging email responses",
				limit: "Yes",
			},
			{
				title: "Advanced metrics dashboard",
				limit: "Yes",
			},
		],
	},
	{
		name: "Outbound University",
		description: "Coming Soon!",
		monthly: 199,
		annual: 2000,
		url: "",
		usage: [
			{
				title: "Multi-stage Voicemail Drops",
				limit: "N/A",
			},
			{
				title: "Customized Images in Outbound Campaigns",
				limit: "N/A",
			},
			{
				title: "Appointment Setting Sales Rep",
				limit: "N/A",
			},
			{
				title: "Personalized Email First Line",
				limit: "N/A",
			},
			{
				title: "A.I. Scheduling Assistant",
				limit: "N/A",
			},
			{
				title: "Direct Dial Numbers",
				limit: "N/A",
			},
			{
				title: "Technology Based Lead Searches",
				limit: "N/A",
			},
			{
				title: "Manual lead qualification/vetting",
				limit: "N/A",
			},
			{
				title: "Appointment tracking",
				limit: "N/A",
			},
			{
				title: "Pre-meeting reminders",
				limit: "N/A",
			},
			{
				title: "Direct calendar bookings & sync",
				limit: "N/A",
			},
			{
				title: "Up To 80h of Manual Lead Chasing per month",
				limit: "N/A",
			},
		],
		essentials: [
			{
				title: "50-70 Prospects Per Day [1500+/m]",
				limit: "N/A",
			},
			{
				title: "Lead list built and approved",
				limit: "N/A",
			},
			{
				title: "Outreach campaigns written and approved",
				limit: "N/A",
			},
			{
				title: "Email outbound prospecting & follow-up",
				limit: "N/A",
			},
			{
				title: "Gather current and past company urls",
				limit: "N/A",
			},
			{
				title: "Validated business emails",
				limit: "N/A",
			},
			{
				title: "Enterprise grade VPN login protection",
				limit: "N/A",
			},
			{
				title: "Monthly video reporting",
				limit: "N/A",
			},
			{
				title: "Dmark, Dkim, SPF record verification & installment",
				limit: "N/A",
			},
			{
				title: "Enrich & track entire prospect list",
				limit: "N/A",
			},
			{
				title: "Social Profile Optimization Guide",
				limit: "N/A",
			},
			{
				title: "Current client exclusions",
				limit: "N/A",
			},
		],
		advanced: [
			{
				title: "Split testing copy monthly",
				limit: "N/A",
			},
			{
				title: "Unlimited messaging update requests",
				limit: "N/A",
			},
			{
				title: "Spam monitoring/fixing",
				limit: "N/A",
			},
			{
				title: "Full domain prep and warmup",
				limit: "N/A",
			},
			{
				title: "A.I. lead response qualification",
				limit: "N/A",
			},
			{
				title: "Logging email responses",
				limit: "N/A",
			},
			{
				title: "Advanced metrics dashboard",
				limit: "N/A",
			},
		],
	},
]

console.log(plans)

const usage = plans.map(item => item.usage)
const essentials = plans.map(item => item.essentials)
const advanced = plans.map(item => item.advanced)
const filterUsage = _.uniqBy(usage, "title")
const filterEssentials = _.uniqBy(essentials, "title")
const filterAdvanced = _.uniqBy(advanced, "title")

console.log(usage)

const TempPricingPage = ({ location }) => {
	const [isToggled, setToggled] = useState(false)
	const toggleTrueFalse = () => setToggled(!isToggled)
	const params = {
		containerClass: "swiper-container px-4 pt-5 d-block d-lg-none",
		spaceBetween: 30,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
	}
	return (
		<Layout location={location}>
			<SEO title="Offers" description="" />
			<div className="py-100 p-relative" id="pricing">
				<img className="oval-svg" src={oval1} alt="Oval svg" />
				<img className="oval-2" src={oval2} alt="Oval svg" />
				<img className="oval-3" src={oval3} alt="Oval svg" />
				<div className="container">
					<h1 className="text-primary-gradient text-center pb-5">Pricing</h1>
					<div className="row">
						<div className="col-lg-3 d-none">
							<div className="switch-container d-flex align-items-center justify-content-center">
								<span
									className={`font-weight-bold ${
										isToggled ? "text-muted" : ""
									}`}
								>
									Retail
								</span>
								<label class="switch mb-0 mx-2" htmlFor="toggleTrueFalse">
									<input type="checkbox" onChange={toggleTrueFalse} />
									<span className="slider round"></span>
								</label>
								<span
									className={`font-weight-bold ${
										isToggled ? "" : "text-muted"
									}`}
								>
									Incentive Base
								</span>
							</div>
						</div>
						<div className="col-lg-9 ml-auto">
							<Swiper {...params}>
								{plans.map((item, idx) =>
									idx !== 2 ? (
										<div className="swiper-slide" key={item.name}>
											<Fade className="h-100">
												<div className="offer-content-wrapper h-100">
													<div className="text-center px-3 pb-3">
														<h4 className="text-primary-gradient">
															{item.name}
														</h4>
													</div>
													<div class="feature-content px-4 pb-3 text-center">
														<p class="feature-item font-weight-bold">
															{item.description}
														</p>
													</div>
													<div className="price-and-cta">
														<div className="text-center px-3">
															<Link
																to={item.url}
																className={`btn btn-primary btn-lg shadow-none ${
																	idx === 1 ? "btn-red" : ""
																}`}
															>
																Get A Price
															</Link>
														</div>
													</div>
												</div>
											</Fade>
											<div className="content py-5 px-4">
												<h6 className="my-4">Featured</h6>
												<hr />
												{item.usage.map(item => (
													<div className="row">
														<div className="col-9 col-md-6">{item.title}</div>
														<div className="col-3 col-md-6 text-center">
															{item.limit === "Yes" ? (
																<img
																	src={require("@images/001-checked.svg")}
																	width="18"
																	alt=""
																/>
															) : item.limit === "No" ? (
																<img
																	src={require("@images/002-closed.svg")}
																	width="16"
																	alt=""
																/>
															) : (
																item.limit
															)}
														</div>
													</div>
												))}

												<h6 className="my-4">Essentials</h6>
												<hr />
												{item.essentials.map(item => (
													<div className="row">
														<div className="col-9 col-md-6">{item.title}</div>
														<div className="col-3 col-md-6 text-center">
															{item.limit === "Yes" ? (
																<img
																	src={require("@images/001-checked.svg")}
																	width="18"
																	alt=""
																/>
															) : item.limit === "No" ? (
																<img
																	src={require("@images/002-closed.svg")}
																	width="16"
																	alt=""
																/>
															) : (
																item.limit
															)}
														</div>
													</div>
												))}

												<h6 className="my-4">Advanced</h6>
												<hr />
												{item.advanced.map(item => (
													<div className="row">
														<div className="col-9 col-md-6">{item.title}</div>
														<div className="col-3 col-md-6 text-center">
															{item.limit === "Yes" ? (
																<img
																	src={require("@images/001-checked.svg")}
																	width="18"
																	alt=""
																/>
															) : item.limit === "No" ? (
																<img
																	src={require("@images/002-closed.svg")}
																	width="16"
																	alt=""
																/>
															) : (
																item.limit
															)}
														</div>
													</div>
												))}
											</div>
										</div>
									) : (
										<div className="swiper-slide">
											<Fade className="h-100">
												<div className="offer-content-wrapper h-100 bg-primary-gradient d-flex flex-column justify-content-center">
													<div class="coming-content px-4 pb-0 text-center">
														<h3 className="text-white">{item.name}</h3>
														<h5 class="text-white-50">{item.description}</h5>
													</div>
												</div>
											</Fade>
										</div>
									)
								)}
							</Swiper>

							<Sticky
								className="sticky-wrapper d-none d-lg-block"
								stickyClassName="is-sticky"
								stickyStyle={{ transform: "unset", zIndex: "10" }}
							>
								<div className="plans">
									<div className="row justify-content-center">
										{plans.map((item, idx) =>
											idx !== 2 ? (
												<div className="col-md-4 mb-4" key={item.name}>
													<Fade className="h-100">
														<div className="offer-content-wrapper h-100">
															<div className="text-center px-3 pb-3">
																<h3 className="text-primary-gradient">
																	{item.name}
																</h3>
															</div>
															<div class="feature-content px-4 pb-3 text-center">
																<p class="feature-item font-weight-bold">
																	{item.description}
																</p>
															</div>
															<div className="price-and-cta">
																<div className="text-center px-3 pt-0">
																	<Link
																		to={item.url}
																		className={`btn btn-primary btn-lg shadow-none ${
																			idx === 1 ? "btn-red" : ""
																		}`}
																	>
																		Get A Price
																	</Link>
																</div>
															</div>
														</div>
													</Fade>
												</div>
											) : (
												<div className="col-md-4 mb-4">
													<Fade className="h-100">
														<div className="offer-content-wrapper h-100 bg-primary-gradient d-flex flex-column justify-content-center">
															<div class="coming-content px-4 pb-0 text-center">
																<h3 className="text-white">{item.name}</h3>
																<h5 class="text-white-50">
																	{item.description}
																</h5>
															</div>
														</div>
													</Fade>
												</div>
											)
										)}
									</div>
								</div>
							</Sticky>
						</div>
					</div>
					<div className="d-none d-lg-block">
						<div className="row">
							<div className="col-12 mx-auto">
								<div className="row align-items-start">
									<div className="col-12">
										<h5 className="mb-4">Featured</h5>
										<hr />
									</div>
									<div className="col-12">
										{filterUsage[0].map((item, idx) => (
											<div className="row">
												<div className="col-md-3">
													<p className="my-2">{item.title}</p>
												</div>
												{plans.map(item => (
													<div className="col-3 px-4">
														<p className="my-2 text-center">
															{item.usage[0].limit === "Yes" ? (
																<img
																	src={require("@images/001-checked.svg")}
																	width="18"
																	alt=""
																/>
															) : item.usage[0].limit === "No" ? (
																<img
																	src={require("@images/002-closed.svg")}
																	width="16"
																	alt=""
																/>
															) : (
																item.usage[0].limit
															)}
														</p>
													</div>
												))}
											</div>
										))}
									</div>
								</div>

								<div className="row align-items-start">
									<div className="col-12">
										<h5 className="mb-4 mt-3">Essentials</h5>
										<hr />
									</div>
									<div className="col-12">
										{filterEssentials[0].map((item, idx) => (
											<div className="row">
												<div className="col-md-3">
													<p className="my-2">{item.title}</p>
												</div>
												{plans.map(item => (
													<div className="col-3 px-4">
														<p className="my-2 text-center">
															{item.essentials[0].limit === "Yes" ? (
																<img
																	src={require("@images/001-checked.svg")}
																	width="18"
																	alt=""
																/>
															) : item.essentials[0].limit === "No" ? (
																<img
																	src={require("@images/002-closed.svg")}
																	width="16"
																	alt=""
																/>
															) : (
																item.essentials[0].limit
															)}
														</p>
													</div>
												))}
											</div>
										))}
									</div>
								</div>

								<div className="row align-items-start">
									<div className="col-12">
										<h5 className="mb-4 mt-3">Advanced</h5>
										<hr />
									</div>
									<div className="col-12">
										{filterAdvanced[0].map((item, idx) => (
											<div className="row">
												<div className="col-md-3">
													<p className="my-2">{item.title}</p>
												</div>
												{plans.map(item => (
													<div className="col-3 px-4">
														<p className="my-2 text-center">
															{item.advanced[0].limit === "Yes" ? (
																<img
																	src={require("@images/001-checked.svg")}
																	width="18"
																	alt=""
																/>
															) : item.advanced[0].limit === "No" ? (
																<img
																	src={require("@images/002-closed.svg")}
																	width="16"
																	alt=""
																/>
															) : (
																item.advanced[0].limit
															)}
														</p>
													</div>
												))}
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="summary-section pb-5 d-none">
				<WaveTop />
				<div className="container">
					<h1 className="text-white text-center pb-5">
						Summary with Bonus + Guarantee
					</h1>
					<div className="row justify-content-center">
						<div className="col-md-6 col-lg-5 mb-4">
							<Fade className="h-100">
								<div className="offer-content-wrapper h-100">
									<div className="bg-dark text-center p-3">
										<h3 className="mb-0 text-white">Offer 1</h3>
									</div>
									<div className="py-3 px-5">
										<ul className="list-unstyled features-list pb-3">
											<li className="feature-item">This is a feature value.</li>
											<li className="feature-item">This is a feature value.</li>
											<li className="feature-item">This is a feature value.</li>
										</ul>
										<span className="font-weight-bold text-primary-gradient text-uppercase small">
											BONUS
										</span>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua.
										</p>
										<span className="font-weight-bold text-primary-gradient text-uppercase small">
											GUARANTEE
										</span>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua.
										</p>
									</div>
									<div className="price-and-cta">
										<div className="text-center feature-item price">
											<span className="font-weight-normal text-muted">
												Total Value
											</span>
											<h4 className="display-5 font-weight-bold text-primary-gradient">
												<sup className="dollar-sup">$</sup>99
											</h4>
										</div>
										<div className="text-center p-3">
											<Link
												to="/"
												className="btn btn-primary btn-lg shadow-none"
											>
												Get A Price
											</Link>
										</div>
									</div>
								</div>
							</Fade>
						</div>

						<div className="col-md-6 col-lg-5 mb-4">
							<Fade delay={500} className="h-100">
								<div className="offer-content-wrapper h-100">
									<div className="bg-primary-gradient text-white text-center p-3">
										<h3 className="mb-0">Offer 2</h3>
									</div>
									<div className="py-3 px-5">
										<ul className="list-unstyled features-list pb-3">
											<li className="feature-item">This is a feature value.</li>
											<li className="feature-item">This is a feature value.</li>
											<li className="feature-item">This is a feature value.</li>
											<li className="feature-item text-primary-gradient">
												This is a feature value.
											</li>
											<li className="feature-item text-primary-gradient">
												This is a feature value.
											</li>
											<li className="feature-item text-primary-gradient">
												This is a feature value.
											</li>
										</ul>
										<span className="font-weight-bold text-primary-gradient text-uppercase small">
											BONUS
										</span>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua.
										</p>
										<span className="font-weight-bold text-primary-gradient text-uppercase small">
											GUARANTEE
										</span>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua.
										</p>
									</div>
									<div className="price-and-cta">
										<div className="text-center feature-item price">
											<span className="font-weight-normal text-muted">
												Total Value
											</span>
											<h4 className="display-5 font-weight-bold text-primary-gradient">
												<sup className="dollar-sup">$</sup>199
											</h4>
										</div>
										<div className="text-center bg-none">
											<Link
												to="/"
												className="btn btn-primary btn-lg shadow-none btn-red"
											>
												Get A Price
											</Link>
										</div>
									</div>
								</div>
							</Fade>
						</div>
					</div>
					<div className="row justify-content-center mt-5">
						<div className="col-md-8 text-center">
							<p className="text-white">
								Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
								consectetur, adipisci velit, sed quia non numquam eius modi
								tempora incidunt ut labore et dolore magnam aliquam quaerat
								voluptatem.
							</p>
						</div>
					</div>
				</div>
				<div className="blob-bg"></div>
			</div>
		</Layout>
	)
}

export default TempPricingPage
